<template>
  <div class="edit-character vx-card no-box-shadow" :class="[{'p-2': $route.name === 'editCharacter'}]">
    <div class="edit-character-fields">
      <custom-validate-input :label="$t('characters.labels.name')"
                             :classes="{'w-full': true}"
                             align="right"
                             rtl
                             :invalid-text="$t('characters.validators.nameHelper')"
                             v-model="character.name"/>

      <u-radio-input
          id="reservableDateRadio"
          :label="$t('characters.labels.reservableDate')"
          class="mt-2"
          :options="reservableDateOptions"
          v-model="character.reservableDate"
      />

      <vs-button id="deleteUserBTN"
                 class="w-full mt-3"
                 color="danger"
                 v-if="checkUserPermissions('character.delete') && character.usersCount === 0"
                 @click="$refs.deleteCharacterConfirmation.showDialog()">
        {{ $t('characters.labels.delete') }}
      </vs-button>
    </div>

    <custom-dialog ref="deleteCharacterConfirmation"
                   :title="$t('characters.confirmations.delete.title')"
                   :body="$t('characters.confirmations.delete.body', {name: character.name.value})"
                   @accept="deleteCharacter"/>

    <vs-button v-show="false"
               id="insertCharacterBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment-jalaali'
  import CustomValidateInput from "../../../../components/customInput/customValidateInput";
  import {editCharacter, deleteCharacter, getCharacter} from "../../../../http/requests/characters";
  import CustomDialog from "../../../../components/customDialog/customDialog";
  import {checkUserPermissions, getTimeFromServer} from "../../../../assets/js/functions";
  import URadioInput from "@/components/customInput/URadioInput.vue";

  export default {
    name: "editCharacter",
    components: {URadioInput, CustomDialog, CustomValidateInput},
    props: {
      characterId: {
        type: [Number, String],
        default: 0
      }
    },
    data() {
      return {
        nameRegex: this.$validator('regex.character.name'),
        dateRegex: this.$validator('regex.date'),
        currentDate: '',
        reservableDateOptions: [
          {
            label: this.$t('characters.labels.disable'),
            value: 0
          },
          {
            label: `1 ${this.$t('characters.labels.week')}`,
            value: 7
          },
          {
            label: `2 ${this.$t('characters.labels.week')}`,
            value: 14
          },
          {
            label: `1 ${this.$t('characters.labels.month')}`,
            value: 30
          },
        ],
        character: {
          name: {
            value: '',
            isValid: true
          },
          reservableDate: {
            value: '',
            isValid: true
          },
          usersCount: 1
        },
        actions: [
          {
            toolbar: [
              {
                id: 'insertCharacterBTN',
                icon: 'SAVE',
                iconPack: 'useral',
                color: 'success',
                permission: 'character.update'
              }
            ],
            leftToolbar: [
              {
                id: 'routeBackBTN',
                route: {name: this.$route.name === 'editCharacter' ? 'character' : 'characters'},
                icon: 'CHEVRON_LEFT',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    created() {
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'mx-auto')
      })

      this.getCharacter()
    },
    mounted () {
      this.currentDate = moment(getTimeFromServer()).format(this.$localeValidator('moment.date'))
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getCharacter () {
        getCharacter(this.characterId || this.$route.params.id).then((response) => {
          const character = response.data.data
          this.character = {
            id: character.id,
            name: {
              value: character.name || '',
              isValid: true
            },
            reservableDate: {
              value: character.reservable_date || 7,
              isValid: true
            },
            usersCount: character.users_count
          }
          if (!this._isBeingDestroyed) {
            this.$store.dispatch('setPageTitle', this.$t('characters.edit.dynamicTitle', {name: this.character.name.value}))
          }
        })
      },
      sendData() {
        if (this.character.name.isValid && parseInt(this.character.reservableDate.value || '0') >= 0) {
          const character = {
            name: this.character.name.value,
            reservable_date: this.character.reservableDate.value
          }
          editCharacter(this.character.id, character).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('characters.notifications.edit.success'),
              icon: 'icon-check',
              iconPack: 'feather',
              time: 2400,
              color: 'success'
            })
            this.$router.push({name: 'characters'})

          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              const error_mapper = {
                'name': this.$t('characters.notifications.parseError.name'),
                'reservable_days': this.$t('characters.notifications.parseError.reservableDays')
              }

              switch (error.response.status) {
                case 422:
                  Object.keys(error.response.data.errors).forEach((error_key) => {
                    const err = error_key.toString().split('.')
                    if (error_mapper[err[err.length - 1]]) {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: error_mapper[err[err.length - 1]],
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      error_mapper[err[err.length - 1]] = null
                    }
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('characters.notifications.edit.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                  break
              }
            }
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('characters.notifications.parseError.name'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        }
      },
      deleteCharacter() {
        deleteCharacter(this.character.id || this.$route.params.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('characters.notifications.delete.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })
          this.$emit('edit')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('characters.notifications.delete.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .edit-character {
    .edit-character-fields {
      max-width: 400px;
    }
    .input-field-item {
      position: relative;
      border: 1px solid #cdcdcd;
      border-radius: 0.4rem;
      padding: 5px 10px;
      margin: 15px 0;
      display: flex;
      justify-content: space-around;
      min-height: 35px;
      flex-wrap: wrap;

      &.invalid {
        border-color: #b00000;

        .input-field-label {
          color: #b00000;
        }
      }

      .input-field-label {
        position: absolute;
        font-size: 12px;
        top: -10px;
        left: 8px;
        background-color: #ffffff;
        padding: 0 5px;
      }

      .radio-item {
        display: flex;
        align-items: center;
        width: max-content;
        direction: rtl;

        label {
          direction: ltr;
          margin-left: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.character-input {
        justify-content: space-between;

        .radio-item {
          justify-content: flex-end;
          width: 25%;
        }
      }
    }
  }
</style>
